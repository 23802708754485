import React, { useEffect } from 'react'
import top from '../assets/top.png';
import flame from '../assets/flame.png';
import pill from '../assets/pill.png';
import p_1 from '../assets/p_1.png';
import p_2 from '../assets/p_2.png';
import p_3 from '../assets/p_3.png';
import p_4 from '../assets/p_4.png';
import twitter from '../assets/twitter.png';
import telegram from '../assets/telegram.png';
import Aos from 'aos';
import 'aos/dist/aos.css';


export default function Home() {

    useEffect(() => {
        Aos.init({
            duration: 800,
        });
        Aos.refresh();
    }, [])

    return (
        <div className="bg-white-100 font-inter overflow-hidden text-green-100">

            <div className="lg:px-20 px-5">
                <div className='container mx-auto'>

                    <div className="flex flex-col justify-center items-center text-center mx-auto gap-y-8 py-10">

                        <div data-aos='zoom-in'>
                            <img src={top} alt='' className='w-8/12 flex mx-auto'></img>
                        </div>

                        <h1 className='md:text-5xl text-2xl font-extrabold text-gradient capitalize'>
                            It's Just a Meme Because<br></br> Every One Wants A CANDLE
                        </h1>

                        <div data-aos='zoom-in' className="flex items-center gap-12 mx-auto">
                            <a href="/">
                                <button className="text-white-100 btn-custom py-4 flex items-center gap-x-2 md:text-lg text-xs md:px-8 px-3 font-semibold mt-5 hover:scale-110 duration-150">
                                    Buy Now
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6">
                                            <path fillRule="evenodd" d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z" clipRule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </a>

                            <a href="https://www.dextools.io/app/en/solana/pair-explorer/BAS6Cv5qG2FnEQ8W1wXKGfU2r6MFAiSdgGgkUM7NsStE?t=1712646042096" className='mt-5'>
                                <button className="text-green-100 font-semibold md:text-lg text-sm px-5 py-3 rounded-lg border-green-100 border-2 hover:scale-110 duration-150">
                                    Chart
                                </button>
                            </a>
                        </div>

                        <div className='lg:flex items-center justify-center gap-8 py-8 lg:space-y-0 space-y-6 text-black-100'>
                            <div className='bg-green-100 rounded-2xl p-6 space-y-2 lg:w-56'>
                                <p className='lg:text-3xl text-xl font-semibold'>Candle Cat</p>
                            </div>

                            <div className='bg-green-100 rounded-2xl p-6 space-y-2 lg:w-56'>
                                <p className='lg:text-3xl text-xl font-semibold'>Symbol</p>
                                <p>$CANDLE</p>
                            </div>

                            <div className='bg-green-100 rounded-2xl p-6 space-y-2 lg:w-56'>
                                <p className='lg:text-3xl text-xl font-semibold'>NO</p>
                                <p>Tax</p>
                            </div>

                            <div className='bg-green-100 rounded-2xl p-6 space-y-2 lg:w-56'>
                                <p className='lg:text-3xl text-xl font-semibold'>1 Billion</p>
                                <p>Total Supply</p>
                            </div>
                        </div>

                        <div className='bg-green-100 text-black-100 rounded-2xl p-8 space-y-2 col-span-4'>
                            <p className='lg:text-2xl text-xs font-semibold'>Contract Address: 6iFUKWGDksVvmnSYJUGYnsu168xstni8xJkTF7QrpPAu </p>
                        </div>

                        <div className='lg:flex items-center justify-between gap-x-12'>
                            <div className='flex items-center justify-center gap-x-5'>
                                <img src={pill} alt='' className='w-28'></img>
                                <p className='lg:text-3xl text-2xl font-semibold text-left text-black-50'>
                                    Supported by<br></br>
                                    pump.fun
                                </p>
                            </div>

                            <div>
                                <img src={flame} alt='' className='flex mx-auto'></img>
                            </div>

                            <div className='lg:flex items-center justify-center gap-12 lg:pt-20 pt-8 pb-8 lg:space-y-0 space-y-4 text-black-100'>
                                <div>
                                    <img src={p_1} alt=''></img>

                                    <div className='flex items-start mt-6 justify-center gap-6'>
                                        <img src={p_2} alt=''></img>
                                        <a href='https://www.dextools.io/app/en/solana/pair-explorer/BAS6Cv5qG2FnEQ8W1wXKGfU2r6MFAiSdgGgkUM7NsStE?t=1712646042096'><img src={p_3} alt=''></img></a>
                                    </div>
                                </div>

                                <img src={p_4} alt='' className='flex mx-auto'></img>
                            </div>
                        </div>

                        <a href="https://www.dextools.io/app/en/solana/pair-explorer/BAS6Cv5qG2FnEQ8W1wXKGfU2r6MFAiSdgGgkUM7NsStE?t=1712646042096" className='mt-5'>
                            <button className="text-green-100 font-semibold md:text-lg text-sm px-6 py-3 rounded-lg border-green-100 border-2 hover:scale-110 duration-150">
                                Get In touch with our team
                            </button>
                        </a>

                        <div className='flex items-center md:mx-0 mx-auto gap-x-6'>
                            <a href='https://x.com/candleonsol/status/1777599332281123078?s=46'>
                                <img src={twitter} alt='twitter'></img>
                            </a>
                            <a href='https://t.me/CandleOnSol'>
                                <img src={telegram} alt='telegram'></img>
                            </a>
                        </div>

                        <div className='py-6 text-center text-green-100'>
                            <p className='lg:w-8/12 lg:text-lg flex mx-auto pb-5'>
                                Join us on this journey. Candle Cat isn't just for the bold; it's for the brilliant, the believers, and yes, the memers. Together, let's light up the crypto world one Candle Cat at a time.
                            </p>
                            <p>Email: admin@candleonsol.io</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
